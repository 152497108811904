import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const IntegrationsPackage = () => (
  <Layout>
    <SEO title="Visual Language Package Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Integrations Package | By Request</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <p>
              Got some complex integrations you need help with?{" "}
              <a
                href="https://hsmgsurveys.typeform.com/to/iYyJ4mlb"
                target="_blank"
                rel="noreferrer"
              >
                Fill out this form
              </a>{" "}
              to give us a better sense of what you need to accomplish, and our
              integrations team will get back to you with a custom scope of work
              and quote.
            </p>
            <p className="text-red">
              This package can only be purchased as an add-on to one of our
              other services. Please return to the services page and select this
              add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default IntegrationsPackage;
